<style>
  div {
    margin: auto;
    width: 150px;
    font-size: 30px;
  }

  div:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
</style>

<div>Loading</div>
